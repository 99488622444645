<template>
  <section id="card-navigation">
    <b-row>
      <!-- pill tabs inside card -->
      <b-col md="12">
        <b-card class="text-center">
          <b-tabs
            v-model="activeTab"
            nav-class="mb-3"
            class="h-scroll-tabs"
            pills
            @input="updateHash"
          >
            <b-tab
              active
              class="text-left"
              href="Posts"
              title="Posts"
            >
              <Posts />
            </b-tab>
            <b-tab
              class="text-left"
              href="Messages"
              title="Messages"
            >
              <Messages />
            </b-tab>
            <b-tab
              class="text-left"
              href="Emails"
              title="Emails"
            >
              <Emails />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  BTab,
  BTabs,
} from 'bootstrap-vue'

import Emails from '@/views/campaigns/pages/campaign-manager/Emails.vue'
import Messages from '@/views/campaigns/pages/campaign-manager/Messages.vue'
import Posts from '@/views/campaigns/pages/campaign-manager/Posts.vue'

export default {
  components: {
    Posts,
    Emails,
    Messages,
    BRow,
    BCol,
    BCard,
    BTab,
    BTabs,
  },
  data() {
    return {
      activeTab: 0,
      tabs: ['#Posts',
        '#Messages',
        '#Emails'],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.activeTab = this.tabs.findIndex(tab => tab === this.$route.hash)
    })
  },
  methods: {
    updateHash(e) {
      this.$router.replace(this.$route.path + this.tabs[e])
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
